// extracted by mini-css-extract-plugin
export var background = "_1-0";
export var container = "_1-1";
export var contentItem = "_1-7";
export var extendedWarranty = "_1-6";
export var modal = "_1-b";
export var modalCloseButton = "_1-9";
export var modalContent = "_1-3";
export var modalContentMain = "_1-5";
export var modalImageLogo = "_1-8";
export var modalLogo = "_1-a";
export var modalSideImage = "_1-4";
export var preview = "_1-c";
export var redoModal = "_1-2";